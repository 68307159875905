<template>
    <div>
        <div class="add-location-map">
            <div class="row justify-content-center mb-3">
                <div class="col-lg-11 col-md-8 col-sm-9 col-9">
                    <form v-on:submit.prevent>
                    <GMapAutocomplete
                        ref="address"
                        placeholder="Find Location"
                        @place_changed="getAddressData"
                        name="search"
                        class="form-control"
                        @keydown.enter.prevent
                    >
                    </GMapAutocomplete>
                    </form>
                </div>
                <div class="col-lg-1 col-md-2 col-sm-3 col-3">
                    <button
                    type="button"
                    class="btn git-location-btn"
                    @click="geolocate"
                    >
                    <i class="fas fa-map-marker-alt"></i>
                    </button>
                </div>
            </div>
            <div id="map" class="full-location-map">
            <GMapMap
                :center="center"
                :zoom="12"
                map-type-id="terrain"
                style="width: 500px; height: 300px"
                @click="setMarker"
            >
                <GMapCluster>
                <GMapMarker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    :clickable="true"
                    :draggable="true"
                    @click="center = m.position"
                    @dragend="getMarkerLocation($event)"
                />
                </GMapCluster>
            </GMapMap>
            </div>
            
        </div>
        <form>
            <!-- <p>{{state.area}}</p> -->
            <div class="mb-3">
                <label class="form-label">{{ $t("locationType") }}</label>
                <select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="state.type"
                >
                    <option value="home">{{ $t("optionHome") }}</option>
                    <option value="work">{{ $t("optionWork") }}</option>
                    <option value="other">{{ $t("optionOther") }}</option>
                </select>
                <span class="error" v-if="v$.type.$error">
                    {{ v$.type.$errors[0].$message }}
                </span>
            </div>
            <div class="mb-3">
                <label class="form-label">{{ $t("title") }} * </label>
                <input type="text" class="form-control" v-model="state.title"  />
                <span class="error" v-if="v$.title.$error">
                    {{ v$.title.$errors[0].$message }}
                </span>
            </div>
            <div class="mb-3">
                <label class="form-label">{{ $t("address") }} *</label>
                <input type="text" class="form-control" v-model="state.area"  />
                <span class="error" v-if="v$.area.$error">
                    {{ v$.area.$errors[0].$message }}
                </span>
            </div>
            <!-- <div class="mb-3">
            <label class="form-label">{{ $t("area") }}</label>
            <input type="text" class="form-control" v-model="state.area" />
            <span class="error" v-if="v$.area.$error">
                {{ v$.area.$errors[0].$message }}
            </span>
            </div> -->
            <div class="mb-3">
            <label class="form-label">{{ $t("apartment") }}</label>
            <input
                type="text"
                class="form-control"
                v-model="state.Apartment"
                :placeholder="$t('apartmentPlaceholder')"
            />
            </div>
            <div class="mb-3">
            <label class="form-label">{{ $t("portCode") }}</label>
            <input
                type="text"
                class="form-control"
                v-model="state.ZIP_code"
            />
            <!-- <span class="error" v-if="v$.ZIP_code.$error">
                {{ v$.ZIP_code.$errors[0].$message }}
            </span> -->
            </div>
            <div class="mb-3">
            <label class="form-label">{{ $t("additional_Instruction") }}</label>
            <textarea
                rows="3"
                class="form-control"
                v-model="state.description"
            >
            </textarea>
            <!-- <span class="error" v-if="v$.description.$error">
                {{ v$.description.$errors[0].$message }}
            </span> -->
            </div>
            <!-- <p class="note mb-0">Delivery Address</p>
                    <p>Full Delivery Address</p> -->
            <div class="row justify-content-center">
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                <button v-if="addressID"
                class="btn btn-primary blue-btn w-100"
                @click.prevent="update()"
                >
                {{ $t("update") }}
                </button>
                <button v-else
                class="btn btn-primary blue-btn w-100"
                @click.prevent="save()"
                >
                {{ $t("save") }}
                </button>
            </div>
            </div>
        </form>
    </div>
</template>


<script>
import { defineComponent } from "vue";
import Header from "@/components/Header.vue"; // @ is an alias to /src
// @ is an alias to /src
import useVuelidate from "@vuelidate/core";
import axios from "axios";
import { reactive, computed } from "vue";
import { required, minLength, maxLength } from "@vuelidate/validators";
export default {
    setup() {
        const state = reactive({
        title: "",
        lat: null,
        lng: null,
        area: null,
        description: "",
        ZIP_code: "",
        Apartment: null,
        type: "home",
        });

        const rules = computed(() => {
        return {
            title: { required, minLength: minLength(3), maxLength: maxLength(100) },
            lat: { required },
            lng: { required },
            type: { required },
            // description: { required },
            area: { required },
            Apartment: { maxLength: maxLength(30) },
        };
        });

        const v$ = useVuelidate(rules, state);

        return {
        state,
        v$,
        };
    },
    data() {
        return {
            center: { lat: 51.093048, lng: 6.84212 },
            currentPlace: null,
            address: null,
            markers: [
                {
                position: {
                    lat: 51.093048,
                    lng: 6.84212,
                },
                },
            ],
            places: [],
            addressID : this.$route.params.id,
        };
    },

    mounted() {
        setTimeout(() => {
        this.geolocate();
        }, 500);
        if(this.$route.params.id){
            this.getAddressFun();
        }
        console.log(this.addressID)
    },
    methods: {
        getAddressFun() {
            axios
                .get(`addresses/${this.$route.params.id}`)
                .then((response) => {
                //Set the center to be showen in the map
                this.center = {
                    lat: parseFloat(response.data.data.lat),
                    lng: parseFloat(response.data.data.lng),
                };

                //Set the markers to show pin in the map of the current address
                this.markers[0].position.lat = parseFloat(response.data.data.lat);
                this.markers[0].position.lng = parseFloat(response.data.data.lng);

                //Set the coming to be show in the inputs
                this.state.title = response.data.data.title;
                this.state.lat = response.data.data.lat;
                this.state.lng = response.data.data.lng;
                this.state.description = response.data.data.description;
                this.state.type = response.data.data.type;
                this.state.area = response.data.data.area;
                this.state.ZIP_code = response.data.data.ZIP_code;
                this.state.Apartment = response.data.data.Apartment;
                })
                .catch((errors) => {
                console.log(errors);
                });
        },
        save() {
            console.log(this.state, " this.state");
            const result = this.v$.$validate();
            if (!this.v$.$error) {
                axios
                .post("addresses", this.state)
                .then((response) => {
                    this.$toast.success(response.data.message, {
                    position: "top-right",
                    });
                    
                    if(this.$route.name == 'Cart'){
                        this.getAddresses();
                        this.hideModal();
                    }else{
                        this.$router.push("/Location");
                    }
                })
                .catch((errors) => {
                    if (errors.response.data.errors) {
                    const Err = errors.response.data.errors;
                    for (const el in Err) {
                        Err[el].map((item) => {
                        this.$toast.error(item, {
                            position: "top-right",
                        });
                        });
                    }
                    } else {
                    const errMsg = errors.response.data.message;
                    this.$toast.error(errMsg, {
                        position: "top-right",
                    });
                    }
                });
            }
        },
        update() {
            console.log("updaaaaaate")
            const result = this.v$.$validate();
            if (!this.v$.$error) {
                axios
                .put(`addresses/${this.$route.params.id}`, this.state)
                .then(() => {
                    this.$toast.success("Updated Successfully!", {
                    position: "top-right",
                    });
                    this.$router.push("/Location");
                })
                .catch((errors) => {
                    if (errors.response.data.errors) {
                    const Err = errors.response.data.errors;
                    for (const el in Err) {
                        Err[el].map((item) => {
                        this.$toast.error(item, {
                            position: "top-right",
                        });
                        });
                    }
                    } else {
                    const errMsg = errors.response.data.message;
                    this.$toast.error(errMsg, {
                        position: "top-right",
                    });
                    }
                });
            }
        },
        getMarkerLocation(event) {
            console.log(event);
            this.addMarker(event.latLng.lat(), event.latLng.lng());
        },

        getAddressData: function (addressData) {
            console.log(addressData.formatted_address);
            this.state.area = addressData.formatted_address;
            this.addMarker(
                addressData.geometry.location.lat(),
                addressData.geometry.location.lng(),
                false
            );
        },

        addMarker(lat, lng, getAddress = true) {
            this.setAccoudinates(lat, lng);
            const marker = {
                lat: lat,
                lng: lng,
            };
            this.markers[0].position = marker;
            this.center = marker;
            // eslint-disable-next-line no-undef
            let geocoder = new google.maps.Geocoder();
            geocoder.geocode({ location: marker }, (results, status) => {
                if (status === "OK" && results.length > 0) {
                this.$refs.address.$refs.input.value = getAddress
                    ? results[0].formatted_address
                    : this.$refs.address.$refs.input.value;

                this.state.area = getAddress
                    ? results[0].formatted_address
                    : this.state.area;
                // this.state.area = results[0].address_components[1].long_name;
                console.log("results : ", results);
                } else {
                console.error("Geocoding request status: " + status);
                console.error(results);
                }
            });
        },
        geolocate: function () {
            navigator.geolocation.getCurrentPosition((position) => {
                this.addMarker(position.coords.latitude, position.coords.longitude);
            });
        },
        setMarker(event) {
            console.log(event);
            this.addMarker(event.latLng.lat(), event.latLng.lng());
        },
        setAccoudinates(lat, lng) {
            this.state.lat = lat;
            this.state.lng = lng;
        },
        hideModal() {
            this.$emit('hideModal');
        },
        getAddresses(){
            this.$emit('getAddresses')
        }
    },
};
  
</script>

<style></style>
